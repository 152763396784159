import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChild, FaBook, FaInfoCircle } from 'react-icons/fa';
import './Home.css';

const Home = () => {
  return (
    <Container className="text-center mt-5">
      <h1 className="mb-4" style={{ color: '#ff69b4' }}>Selamat Datang di Portal Informasi TK</h1>
      <h3 className="mb-5" style={{ color: '#ff1493' }}>Perkembangan, Nilai, dan Informasi Murid Ada di Sini</h3>
      <Row>
        <Col md={4}>
          <Card className="info-card">
            <Card.Body>
              <FaChild className="icon mb-3" />
              <Card.Title>Perkembangan Anak</Card.Title>
              <Card.Text>
                Pantau perkembangan anak Anda dengan laporan mingguan yang menyenangkan.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="info-card">
            <Card.Body>
              <FaBook className="icon mb-3" />
              <Card.Title>Nilai Anak</Card.Title>
              <Card.Text>
                Dapatkan informasi nilai anak Anda dengan cara yang mudah dan transparan.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="info-card">
            <Card.Body>
              <FaInfoCircle className="icon mb-3" />
              <Card.Title>Informasi Sekolah</Card.Title>
              <Card.Text>
                Akses informasi penting tentang sekolah Anda dengan cepat dan mudah.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
