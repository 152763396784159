import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChild, FaBook, FaSchool } from 'react-icons/fa';
import './Dashboard.css';

const Dashboard = () => {
  const userName = localStorage.getItem('user_name');

  return (
    <Container className="text-center mt-5">
      <h1 className="mb-4" style={{ color: '#ff69b4' }}>Selamat Datang, {userName}!</h1>
    </Container>
  );
};

export default Dashboard;
