import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setAuth }) => {
  const [nip, setNip] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://simpegsehat.deliserdangkab.go.id/murid/auth/login', { 
        nip: parseInt(nip, 10), // Convert NIP to integer
        password 
      });
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('user_name', response.data.data.nama); // Save user name
      setAuth(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="my-4">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="nip" className="form-label">NIP</label>
          <input
            type="text"
            className="form-control"
            id="nip"
            placeholder="Enter NIP"
            value={nip}
            onChange={(e) => setNip(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>
    </div>
  );
};

export default Login;
